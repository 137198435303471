import React from 'react';
import logo from "../neoxure-white-baseline-nobg.svg";
const Home = () =>{
    return (
        <div className="App-header">
        {/*<div>*/}
            <header className="App-header" style={{marginTop: -70}}>
                <img src={logo} className="App-logo mb-9" alt="logo"/>
                <p className="baseline-shadow font-light">Website under construction...</p>
            </header>
        </div>

    );
}
export default Home;