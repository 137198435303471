import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const ServiceCard = ({title, text, icon}) => {
    return (
        <div className="bg-gradient-to-br from-purple-500 to-purple-800
        hover:bg-gradient-to-tl hover:from-purple-500 hover:to-purple-600
          text-white p-6 rounded-lg shadow-md hover:bg-purple-500">
            <div className="flex items-center mt-1">
                <FontAwesomeIcon icon={icon} className="text-xl mr-3"/>
                <h3 className="text-lg font-semibold">{title}</h3>
            </div>
            <p className="mt-2">{text}</p>
        </div>
    );
};

export default ServiceCard;