import React from 'react';
import { Link, NavLink } from "react-router-dom";
import logo from "../neoxure.svg";

const Navbar = () => {
    return (
        <header className="flex flex-wrap sm:justify-start sm:flex-nowrap z-50 w-full bg-black text-sm py-4 dark:black">
            <nav className="max-w-[85rem] w-full mx-auto px-4 sm:flex sm:items-center sm:justify-between" aria-label="Global">
                <Link to="/">
                    <img src={logo} style={{ height: 20 }} alt="neoxure" />
                </Link>
                <div className="flex flex-row items-center gap-5 mt-5 sm:justify-end sm:mt-0 sm:pl-5">
                    <NavLink exact to="/" activeClassName="font-medium text-blue-500" className="font-medium text-gray-600 hover:text-gray-400 dark:text-gray-400 dark:hover:text-gray-500">
                        Home
                    </NavLink>
                    <NavLink to="/about" activeClassName="font-medium text-blue-500" className="font-medium text-gray-600 hover:text-gray-400 dark:text-gray-400 dark:hover:text-gray-500">
                        About
                    </NavLink>
                    <NavLink to="/services" activeClassName="font-medium text-blue-500" className="font-medium text-gray-600 hover:text-gray-400 dark:text-gray-400 dark:hover:text-gray-500">
                        Services
                    </NavLink>
                    <NavLink to="/contact" className="py-2 px-3 inline-flex justify-center items-center gap-2 rounded-md sm:rounded-full border border-transparent font-semibold text-white bg-gradient-to-br from-purple-500 to-purple-800 hover:bg-gradient-to-tl hover:from-purple-500 hover:to-purple-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-all text-sm md:py-1 md:px-4">
                        Contact
                    </NavLink>
                </div>
            </nav>
        </header>
    );
}

export default Navbar;
