import React, {useCallback, useEffect, useState} from 'react';
import './App.css';
import particlesOptions from "./particles.json"
import Particles from "react-particles";
import {loadFull} from "tsparticles";
import {Route, BrowserRouter, Routes, useLocation} from "react-router-dom";
import Navbar from "./components/Navbar";
import Home from './views/homeView';
import About from './views/aboutView';
import Services from './views/servicesView';
import Contact from './views/contactView';
import "./transitionStyles.css"

function Content() {
    const location = useLocation();

    const [displayLocation, setDisplayLocation] = useState(location);
    const [transitionStage, setTransistionStage] = useState("fadeIn");

    useEffect(() => {
        if (location !== displayLocation) setTransistionStage("fadeOut");
    }, [location, displayLocation]);

    return (
        <div
            className={`${transitionStage}`}
            onAnimationEnd={() => {
                if (transitionStage === "fadeOut") {
                    setTransistionStage("fadeIn");
                    setDisplayLocation(location);
                }
            }}
        >
            <Routes location={displayLocation}>
                <Route path='/' element={<Home/>}/>
                <Route path='/about' element={<About/>}/>
                <Route path='/services' element={<Services/>}/>
                <Route path='/contact' element={<Contact/>}/>
            </Routes>
        </div>
    );
}

function App() {
    const particlesInit = useCallback(main => {
        loadFull(main);
    }, [])

    return (
        <div className="App">
            <Particles options={particlesOptions} init={particlesInit}/>
            <BrowserRouter>
                <Navbar />
                <Content />
            </BrowserRouter>
        </div>
    );
}

export default App;
