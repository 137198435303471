import React from 'react';
import ServiceCard from "../components/ServiceCard";
import {
    faCode,
    faGamepad,
    faHeadphones,
    faLaptopCode,
    faTools,
    faNetworkWired,
    faCloud,
    faLock
} from '@fortawesome/free-solid-svg-icons';

const Services = () => {
    return (
        <div>
            <p className="baseline-shadow mb-9 mt-9 font-bold text-xl">Services :</p>

            <div className="px-4 sm:px-6 md:px-8 lg:px-10 xl:px-12">
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-8
                 overflow-y-auto justify-center mx-auto">
                    <ServiceCard
                        title="Audio Development"
                        text="Innovative audio plugins and applications development for enhanced audio experiences."
                        icon={faHeadphones}
                    />
                    <ServiceCard
                        title="Game Development"
                        text="Immersive gaming experiences with cutting-edge technology and design."
                        icon={faGamepad}
                    />

                    <ServiceCard
                        title="Web Development"
                        text="Responsive, user-friendly websites optimized for performance and scalability."
                        icon={faCode}
                    />

                    <ServiceCard
                        title="Software Dev Consulting"
                        text="Custom software design and engineering to propel your business forward."
                        icon={faLaptopCode}
                    />

                    <ServiceCard
                        title="IT Support/System Admin Consulting"
                        text="Reliable IT and system management solutions for optimal business operations."
                        icon={faTools}
                    />

                    <ServiceCard
                        title="DevOps Consulting"
                        text="Streamlined workflows and automated solutions for faster and more reliable delivery."
                        icon={faNetworkWired}
                    />

                    <ServiceCard
                        title="Cloud Architecture Consulting"
                        text="Scalable and secure cloud strategies to modernize your infrastructure and services."
                        icon={faCloud}
                    />

                    <ServiceCard
                        title="InfoSec Consulting"
                        text="Comprehensive cybersecurity strategies tailored to defend your digital ecosystem. Secure your data and operations against evolving threats."
                        icon={faLock}
                    />
                </div>
            </div>
        </div>
    );
}
export default Services;