import React from 'react';
import {NavLink} from "react-router-dom";

const About = () => {
    return (
        <main className="profile-page">
            <section className="relative block h-96">
                <div className="absolute top-0 w-full h-full bg-center bg-cover"/>
                <div
                    className="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden h-70-px"
                    style={{transform: 'translateZ(0px)'}}>
                </div>
            </section>
            <section className="relative py-16">
                <div className="container mx-auto px-4">
                    <div
                        className="relative flex flex-col min-w-0 break-words
                         bg-gradient-to-r from-violet-900 to-indigo-900
                         w-full mb-6 shadow-xl rounded-lg -mt-64">
                        <div className="px-6">
                            <div className="flex flex-wrap justify-center">
                                <div className="w-full lg:w-3/12 px-4 lg:order-2 flex justify-center">
                                    <img alt="..."
                                         src="/leozor.jpeg"
                                         className="shadow-xl rounded-full h-auto align-middle border-none absolute -m-16 -ml-20 lg:-ml-16 max-w-150-px"
                                    />
                                </div>
                                <div className="w-full lg:w-4/12 px-4 lg:order-3 lg:text-right lg:self-center">
                                    <div className="py-6 px-3 mt-32 sm:mt-0">
                                        <NavLink
                                            to="https://www.linkedin.com/in/leo-fabre/"
                                            target="_blank"
                                            className="bg-pink-500 active:bg-pink-600 uppercase text-white font-bold hover:shadow-md shadow text-xs px-4 py-2 rounded outline-none focus:outline-none sm:mr-2 mb-1 ease-linear transition-all duration-150"
                                            type="button">
                                            LinkedIn
                                        </NavLink>
                                    </div>
                                </div>
                                <div className="w-full lg:w-4/12 px-4 lg:order-1">
                                </div>
                            </div>
                            <div className="text-center mt-7">
                                <h3 className="text-4xl font-semibold leading-normal mb-2 text-cyan-50 mb-2">
                                    Leo Fabre
                                </h3>
                                <div className="text-sm leading-normal mt-0 mb-2 text-blueGray-400 font-bold uppercase">
                                    <i className="fas fa-map-marker-alt mr-2 text-lg text-blueGray-400"></i>
                                    Mézy sur Seine, France
                                </div>
                                <div className="mb-2 text-blueGray-400 mt-8">
                                    <i className="fas fa-briefcase mr-2 text-lg text-blueGray-400"></i>Student and CEO
                                </div>
                                <div className="mb-2 text-blueGray-400">
                                    <i className="fas fa-university mr-2 text-lg text-blueGray-400"></i>Epitech Technology Institute
                                </div>
                            </div>
                            <div className="mt-10 py-10 border-t border-blueGray-200 text-center">
                                <div className="flex flex-wrap justify-center">
                                    <div className="w-full lg:w-9/12 px-4">
                                        <p className="mb-4 text-lg leading-relaxed text-blueGray-400">
                                            Passionate about music and computing from a young age, Leo, now the founder of Neoxure, has always known how to combine his interests to create a unique universe.<br /><br />
                                            A fan of music and sound, he finds in skateboarding a form of freedom that complements his love for technology.<br /><br />
                                            His academic journey at Epitech, marked by a specialization in Cybersecurity and video game projects, has been the catalyst for a career dedicated to technological innovation.<br />
                                            Leo stands out for his sharp expertise in software, with a predilection for game development.<br />
                                            However, his versatility also leads him to app and web development, demonstrating a complete mastery of the digital ecosystem.<br /><br />
                                            With Neoxure, Leo commits to offering top-tier IT services and the development of customized applications.<br />
                                            His goal is to transform each project into a unique experience, combining creativity, performance, and security.<br />
                                            His approach is always guided by passion and excellence.<br /><br />
                                            His vision for Neoxure is clear: To push the boundaries of technology and creativity.<br />
                                            Join this technological adventure, where each project becomes an opportunity to push the limits of the imaginable.
                                        </p>

                                        {/*<p className="mb-4 text-lg leading-relaxed text-blueGray-400">*/}
                                        {/*    Passionné de musique et d'informatique depuis toujours, Leo, aujourd'hui fondateur de Neoxure, a toujours su allier ses intérêts pour créer un univers unique. <br />*/}
                                        {/*    Fan de musique et de son, il trouve dans le skate une expression de liberté qui complète son amour pour la technologie. <br />*/}
                                        {/*    <br />*/}
                                        {/*    Son parcours académique à Epitech, ponctué d'une spécialisation en CyberSécurité et de projets de jeu vidéo, a été le catalyseur d'une carrière dédiée à l'innovation technologique. <br />*/}
                                        {/*    Leo se distingue par une expertise pointue dans le software, avec une prédilection pour le game development. <br />*/}
                                        {/*    Cependant, sa polyvalence le mène aussi vers l'app et le web development, démontrant une maîtrise complète de l'écosystème numérique. <br />*/}
                                        {/*    <br />*/}
                                        {/*    Avec Neoxure, Leo s'engage à proposer des services informatiques de premier plan et le développement d'applications personnalisées. <br />*/}
                                        {/*    Son objectif est de transformer chaque projet en une expérience unique, alliant créativité, performance et sécurité. <br />*/}
                                        {/*    Son approche est toujours guidée par la passion et l'excellence. <br />*/}
                                        {/*    <br />*/}
                                        {/*    Sa vision pour Neoxure est claire : Repousser les frontières de la technologie et de la créativité. <br />*/}
                                        {/*    Rejoignez cette aventure technologique, où chaque projet devient une opportunité de repousser les limites de l'imaginable. <br />*/}
                                        {/*</p>*/}
                                        <NavLink to="/services" class="font-normal text-red-600">See more...</NavLink>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
}
export default About;