import React from 'react';
const Contact = () =>{
    return (
        <div className="about">
            <header className="App-header" style={{marginTop: -100}}>
                <p className="baseline-shadow mb-5">Contact :</p>
                <a className="py-2 px-3 inline-flex justify-center items-center gap-2
            rounded-md sm:rounded-full border border-transparent font-semibold text-white
              bg-gradient-to-br from-purple-500 to-purple-800
              hover:bg-gradient-to-tl hover:from-purple-500 hover:to-purple-600
               focus:outline-none focus:ring-2 focus:ring-blue-500
              focus:ring-offset-2 transition-all text-sm md:py-4 md:px-8"
                   href="mailto:contact@neoxure.com?subject=Contact">
                    Send an Email
                </a>
            </header>
        </div>
    );
}
export default Contact;